.price-card-button-div{
    padding-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.price-card-button-inner{
    height: 3.5rem;
    // width: 32rem;
    border-radius: 50%;
    background-color: white;
    display: flex;
    box-shadow: 0 0 30px rgb(0 0 0 / 50%);
    justify-content: space-between;

}
.popular-main-price{
    font-weight: 700;
    font-size: 2rem;
}
.custom-main-sub{
    font-weight: 500;
    font-size: 1rem;

}

.pc-btn-left-main{
    height: 3.5rem;
    width: 16rem;
    border-radius: 30px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    background-color: white;
    cursor: pointer;
}
.pc-btn-right-main{
    height: 3.5rem;
    width: 16rem;
    border-radius: 30px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    background-color: white;
    cursor: pointer;
}
.price-tooltip{
    padding-left: 0.1rem;
    padding-top: 0.5rem;
    cursor: pointer;
}
.pc-icon-btn{
    padding-left: 0.5rem;
}
.isActive{
    background-color: black;
}
.highlight{
    background-color: #6A3394;
    color: white;
    border: 3px solid skyblue;
}
.price-tbl-btn{
    height: 2.6rem;
    width: 6rem;
    font-weight: 18px;
    border-radius: 30px;
    font-weight: bold;
    background-color: white;
    border: 1px solid #6A3394;
    color: #6A3394;
    
    cursor: pointer;
    &:hover{
        background-color: #6A3394;
        color: white;
    }

}
.price-tbl-head-inner{
    display: flex;
    width: 100%;
    padding: 3rem 1rem;
}
.price-tbl-head-left{
    width: 25%;
}
.price-tbl-head-right{
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}
.price-tbl-head-item-right{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-content: center;
    background-color: #fff;
}
.price-item-main-heading-main{
    font-size: 21px;
    font-weight: 700;
    line-height: 1.2;
    color: #505050!important;
    padding-bottom: 2rem;

}
.item-head-left-cont{
    padding-top: 2rem;
    font-weight: bold;
    font-size: 14px;
    color: #505050!important;
}
.price-item-main-sub-cont{
    font-size: 14px;
    padding-right: 1rem;
    color: #505050;
}
.price-tbl-outer{
    display: block;

}
.responsive-tbl{
    display: none;
}
// -------------------responsive-table-section-------------------
.resp-tbl-inner{
    padding: 2rem 0;
}
.resp-accordion{
    // background-color: #505050!important;
    // color: white!important;
    border-radius: 10px!important;
    margin: 0.5rem 0;
}
.resp-title-sub{
    padding: 1rem 0;
    text-align: right;
    font-size: 14px;
    color: #505050;
}
.resp-tbl-acrdn-inner{
    padding: 0 1rem;
}
.resp-tbl-box-title-div{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    
}
.resp-tbl-box-title{
    width: 100%;
    color: #505050!important;
    font-weight: bold;
    background-color: #EDEDED;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

}
.val-fetch{
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #0000002b;
    // position: relative;
}
.resp-box-title-head{
    font-size: 14px;
    font-weight: 700;
    color: #505050!important;
}
.val-fetch-main{
    color: #505050;
    font-size: 14px;
}

@media(max-width:800px){
    .price-tbl-outer{
        display: none;
    }
    .responsive-tbl{
        display: block;
    }
}

@media(max-width:850px){
    .buy-price-sec{
        display: flex;
        justify-content: space-between;
        margin: auto;
    }
}



@media(max-width:750px){
    .price-card-button-inner{
        flex-wrap: wrap;
        background-color: white;
    box-shadow:none;
    width: 100%;
    justify-content: center;
    height: auto;
    }

}