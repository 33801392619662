.energy-ban-div{

}
.energy-ban-inner{
    height: 32rem;
    width: 100%;
    background-image: url("https://d1suf988f4fj5v.cloudfront.net/images/renewable-energy-header-img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.energy-ban-hero{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.energy-ban-hero-title{
    font-weight: 800 ;
    font-size: 50px ;
    color: #fff ;
    text-shadow: 0 1px 5px rgb(0 0 0 / 30%);
}
.energy-ban-hero-sub{
    color: #fff;
    font-size: 20px;
    line-height: 36px;
    font-weight: 300;
    width: 60rem;
    padding-top: 2rem;
    text-align: center;
    text-shadow: 0 1px 5px rgb(0 0 0 / 30%);
}
.energy-ban-hero-sub2{
    color: #fff;
    font-size: 20px;
    line-height: 36px;
    font-weight: bolder;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    text-shadow: 0 1px 5px rgb(0 0 0 / 30%);
}
.energy-ban-hero-btn{
    height: 3rem;
    width: 10rem;
    border: none;
    background-color: #662D91;
    color: white;
    border-radius: 25px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
        background-color: #56118a;
    }
}

@media(max-width:1000px){
    .energy-ban-hero{
        padding: 0rem 2rem;
    }
    .energy-ban-hero-sub{
        font-size: 14px;
        line-height: 25px;
        font-weight: 300;
        width: auto;
    }
    .energy-ban-hero-title{
        font-weight: 800 ;
        font-size: 30px ;
        text-align: center;
    }
}