.price-banner-div{
    width: 100%;
}
.price-banner-inner{
    height: 100%;
    width: 100%;
    background-image: url('https://d1suf988f4fj5v.cloudfront.net/images/reward-banner-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}