.emp-banner{
    padding: 0rem 6rem;
    height: 14rem;
    width: 100%;
    background-color: #9F00FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}
.emp-banner-img{
    height: 8rem;
}
.emp-banner-text{
    font-size: 35px;
}
.emp-intro{
    padding: 3rem 7rem;
}
.emp-intro-inner{
    width: 100%;
    
}
.emp-intro-title-main{
    font-size: 25px;
    font-weight: 400;
    color: #505050;
}
.emp-intro-sub{
    padding-top: 2rem;
    width: 100%;
    display: flex;
}
.emp-intro-sub-left{
    width: 65%;
}
.emp-intro-img{
    height: 16rem;
}
.emp-intro-para{
    font-size: 15px;
    line-height: 1.8;
    color: #505050;
    padding-bottom: 1rem;
    text-align: justify;
}
@media(max-width:1050px){
    // .emp-intro-img{
    //     height: 12rem;
    // }

}
@media(max-width:900px){
    .emp-banner-img{
        display: none;
    }
    .emp-banner{
        padding: 0rem 3rem;
       
        
    }
    .emp-banner-text{
        font-size: 25px;
    }
}
@media(max-width:800px){
    .emp-intro-sub-left{
        width: 100%;
    }
    .emp-intro-sub{
        flex-direction: column;
    }
    .emp-banner-img{
        height: 15rem;
    }
    .emp-intro-sub-right{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .emp-intro{
        padding: 2rem 2rem;
    }

}

@media(max-width:500px){
    .emp-banner-img{
        height: 9rem;
    }

}