.energy-intro-div{
    width: 100%;
    padding: 4rem 8rem;
    background-color: white;
}
.energy-intro-inner{
    width: 100%;
    display: flex;
   

}
.eng-int-sub{
    padding-top: 1rem;
    text-align: justify;
}
.eng-title-width{
    width: 30rem;

}
.eng-int-left{
    width: 50%;


}
.eng-int-right{
    width: 50%;

}
.eng-int-img{
    height: 23rem;

}
.eng-int-sec2{
    background-color: #F6F8F9;
    padding: 4rem 8rem;
}
.para-pad{
    padding-top: 1rem;
}
.eng-int-title-main{
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
}
.eng-int-sec3{
    padding: 4rem 8rem;
    background-color: #F6F8F9;

}

@media(max-width:1000px){
    .energy-intro-inner{
        flex-wrap: wrap;
        gap: 2rem;
       
    
    }
    .eng-title-width{
        width: auto;
    
    }
    .eng-int-left{
        width: auto;
    }
    .eng-int-right{
        width: 100%;
    
    }
    .eng-int-img{
        height: 100%;
        width: 100%;
    
    }
    .energy-intro-div{
        padding: 2rem 2rem;
    }
    .eng-int-sec2{
        padding: 2rem 2rem;
    }
    .eng-int-sec3{
        padding: 2rem 2rem;
    
    }

}