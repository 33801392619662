.req-form-outer{
    padding: 3rem 0;
}
.req-form-coutrycode{
    display: flex;
}
.req-form-width{
    width: 100%;
}
.req-demo-img{
    height: 16.5rem!important;
    width: 100%!important;
}
.req-form-check{
    display: flex;
    padding: 0.5rem;
}
.req-check-text-main{
    padding-left: 0.5rem;
    font-size: 14px;
}
.req-check-span{
    text-decoration: underline;
    color: #662D91;
}
.req-form-btn-main{
    height: 3rem;
    width: 10rem;
    cursor: pointer;
    border: none;
    background-color: #00AAB4;
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.req-form-btn{
    padding-top: 1rem;
}

@media(max-width:1000px){
    .req-form-outer{
        padding: 2rem 2rem;
    }
    .req-demo-img{
        height: 100%;
        width: 100%;
    }

}