.energy-contact-div{
    height: 28rem;
    width: 100%;
}
.energy-contact-inner{
    height: 100%;
    width: 100%;
}
.energy-contact-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}
.energy-contact-title-main{
    font-weight: 900;
    font-size: 40px;
    padding: 2rem 0rem;
    text-align: center;
}
.energy-contact-sub-main{
    color: #fff;
    font-size: 18px;
    line-height: 31px;
    font-weight: 300;
    margin-bottom: 30px;
    width: 47rem;
    text-align: center;
}
.energy-contact-btn{
    text-align: center;
}
.energy-contact-btn-main{
    height: 3rem;
    width: 12rem;
    border: none;
    background-color: #662E91;
    color: white;
    border-radius: 25px;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
        background-color: #491d6b;
    }
}

@media(max-width:900px){
    .energy-contact-sub-main{
        color: #fff;
        font-size: 13px;
        width: auto;
        line-height: 20px;
        
    }
    .energy-contact-title-main{

        font-size: 30px;
    }
    .energy-contact-main{
        padding: 1rem 2rem;
    }

}
