.demo-page{
    padding-top: 4rem;
    padding-bottom: 4rem;
}
// .demo-page-inner{
//     margin: 0rem 7.5rem;

// }
.demo-page-para{
    color: #505050;
    font-size: 15px;
    line-height: 1.8;
}
.demo-header-inner{
    text-align: justify;
}
.demo-para-link{
    color: #662E91;
    font-weight: bold;
}
.demo-card-div{
    padding-top: 4rem;
}
.demo-card-header{
    display: flex;
    justify-content: center;
}
.demo-card-div-inner{
    display: flex;
    justify-content: center;
    gap: 2rem;
}
.demo-card{
    width: 22rem;
    border-radius: 10px;
    box-shadow:0px 0px 2px 1px rgba(0,0,0,0.1); 
}
.demo-card-inner{
    padding: 1rem;
}
.demo-card-heading{
    font-size: 25px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #505050!important;
}

.demo-card-image{
    height: 14rem;
    width: 100%;
}
.demo-card-img-main{
    height: 100%;
    width: 100%;
}
.demo-card-para{
    display: flex;
    justify-content: center;
}
.demo-card-para-main{
    color: #505050!important;
    font-size: 15px;
    line-height: 1.8;
    text-align: center;
    padding: 1rem 1rem    ;
}
.demo-card-para-ref{
    color: #662E91;
    font-weight: bold;

}
.demo-card-btn{
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
}
.demo-card-btn-main{
    height: 2.9rem;
    width: 7rem;
    color: white;
    background-color: #202020;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    cursor: pointer;
}
.demo-card-para2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1rem;
    color: #505050;
    font-size: 15px;
    line-height: 1.8;
}
.demo-card-image2{
    height: 14rem;
    width: 100%;
    
    
}
.demo-card-admin-text{
    color: #662E91!important;
    font-size: 15px;
    line-height: 1.8;
    font-weight: bold;
}
.demo-icons{
    width: 100%;
}
.demo-icons-inner{
    display: flex;
    justify-content: center;
    gap: 2rem;

    width: 100%;

}
.demo-icons-img{
    height: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
@media(max-width:900px){
    .demo-page-inner{
        // margin: 0rem 3rem;
    
    }

}
@media(max-width:950px){
    .demo-card-div-inner{
        flex-wrap: wrap;
    }

}

@media(max-width:700px){
    .demo-page-inner{
        margin: 1rem 0;
    
    }
    .demo-icons-inner{
        flex-wrap: wrap;
        gap: 1rem;
        padding-top: 1rem;
    
    }
    .demo-icons-img{
        height: 3rem;
        margin: 0rem 0rem;
    }
    .demo-page{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .demo-card-div{
        padding-top: 2rem;
    }
    

}