.Lending-feature-div{
    background-color: white;
}
.lend-feature-inner{
    padding-top: 4rem;
}
.lend-feature-title{
    width: 100%;
    text-align: center;
}

.lend-feature-title-main{
    font-size: 25px;
    font-weight: 400;
    color: #505050;

}
.lend-feature-sub{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

}
.lend-feature-sub-main{
    width: 48rem;
    color: #505050;
    font-family: Rubik, sans-serif;
    font-size: 15px;
    line-height: 1.8;

}
.lend-feature-cards{
    width: 100%;
    padding: 1rem 6rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lend-cards-inner{
    
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}
.lend-feature-card{
    
    min-height: 230px;
    width: 22rem;

}
.lend-feature-card-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.lend-card-img{
    height: 5rem;
}
.lend-card-title{
    padding-top: 20px;
    text-align: center;
    display: flex;
    gap: 0.3rem;
    justify-content: center;
    align-items: center;

}
.lend-card-btn{
    height: 1.1rem;
    width: 3.4rem;
    color: white;
    background-color: #DC3545;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;


}
.lend-card-title-main{
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    color: #505050;
}

.lend-card-sub-main{
    font-size: 14px;
    text-align: center;
    color: #505050;
    line-height: 1.8;

}
@media(max-width:800px){
    .lend-feature-sub-main{
        margin: 1rem 2rem;
    
    }
    .lend-card-sub-main{
        line-height: 1.5;
    
    }

}
@media(max-width:470px){
    .lend-card-title{
        flex-wrap: wrap;
    
    }
    .lend-feature-cards{
        padding: 1rem 2rem;
    }
}