.energy-highlight-div{
    padding: 3rem 8rem;
    background-color: white;
}
.eng-hlt-title-main{
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    width: 100%;
    text-align: center;
    padding-bottom: 1.5rem;
}
.eng-hlt-cards-inner{
    justify-content: flex-start;
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.eng-hlt-card{
    min-height: 18rem;
    // width: 16rem;
    border: 1px solid #00000029;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}
.eng-hlt-card-title-main{
    margin-bottom: 5px;
    display: block;
    font-weight: bolder;
    color: #282828;
}
.eng-hlt-card-content{
    padding: 1.5rem 1.5rem;
}
.eng-hlt-card-sub{
    padding-top: 0.5rem;
}
.eng-hlt-card-sub-main{
    line-height: 25px;
    color: #505050;
    font-size: 15px;
}
.eng-hlt-card-icon-div{
    height: 7.5rem;
    width: 7.5rem;
    background-color: #672f924a;
    border-radius: 50%;
    position: absolute;
    right: -49px;
    bottom: -48px;
}
.eng-card-img{
    position: relative;
    right: -25px;
    bottom: -25px;
}
@media(max-width:1000px){
    .energy-highlight-div{
        padding: 2rem 2rem;
    }

}
