.slider_style {
  position: relative;

  display: flex !important;
  overflow: hidden;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  scroll-behavior: smooth;
  justify-content: flex-start;

  scroll-snap-type: x mandatory;
  // scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);
  scroll-padding: 0px;
  scrollbar-width: 0;
}
.slider_style::-webkit-scrollbar {
  display: none;
}

.individual_gallery_div {
  min-width: 100vw;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  height: 22rem;
}
//
//
//
//
//

.carousel-item {
  height: 500px;
  position: relative;
  float: left;
  // float: right;
  // margin-right: -100%;
  // overflow: hidden;
  // backface-visibility: hidden;
}
.carousel-caption {
  position: absolute;
  // right: 15%;
  // left: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: $base-rem * 3;
}
.left-15 {
  left: 15%;
}
.right-5 {
  right: 5%;
}
.new-slider {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  color: black;
  max-width: 500px;
  text-align: left;
}
.slider-bg {
  height: 500px;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border: none;
    vertical-align: middle;
  }
}

.btn-img-div-wrapper {
  position: relative;
}
// btn style
.main-btn-style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.btn-div {
  height: 2rem !important;
  width: 2rem !important;
  cursor: pointer;
  color: black !important;
  border: 2px solid rgb(65, 64, 64) !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    height: 2.3rem !important;
    width: 2.3rem !important;
    background-color: gray;
    border: none !important;

    color: whitesmoke !important;
  }
}

.individual-btn-style {
  font-size: 1rem !important;
}
// indicator style
.slider-indicator-ol {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.slider-indicator-li {
  background-color: black !important;
  opacity: 0.1;
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 2px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.indicator-active {
  // background-color: black !important;
  opacity: 1;
}

@media screen and (max-width: 750px) {
  //   .banner-wrap .carousel-item {
  //     height: 370px;
  //   }
  // .carousel-item {
  //   height: 370px;
  // }
  .new-slider {
    left: 30px !important;
    right: 30px !important;
    width: calc(100% - 60px);
    text-align: center !important;
  }
  .slider-bg {
    opacity: 0.2;
  }
  .first-content-ul {
    display: inline-block;
  }
}
