.main-solution-container {
  position: absolute !important;
  top: 70px !important;
  left: -210px !important;
  //   right: 0 !important;
  //   padding-top: 3rem !important;
  //   transform: translate(-200px, 5px) !important;
  width: fit-content !important;
}

.solution-div-wrapper {
  background-color: white;
  box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.1);
  //   width: fit-content;
  padding: $base-rem * 2;
  width: 48rem;
}
.solution-right-icon-span {
  opacity: 0;
  transform: translateX(-37px) !important;
  transition: all 0.3s ease-in-out;
}

.solution-grid-item {
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #ebd5fc7e;

    .hover-solution-desc {
      display: flex !important;
    }
    .solution-desc {
      display: none !important;
    }
    .solution-right-icon-span {
      opacity: 1;
      transform: translateX(0px) !important;
    }
  }
}
// &:hover .hover-solution-desc {
//   display: block !important;
// }
// &:hover .solution-desc {
//   display: none !important;
// }
// &:hover {
//   .solution-right-icon-span {
//     opacity: 1;
//     display: block !important;
//     transform: translateX(0) !important;
//   }
// }

// solution
.font-size-0_8 {
  font-size: 0.8rem !important;
}
.li-border {
  border-right: 2px solid #777;
}
.solution-li {
  &:hover {
    a {
      color: #662992 !important;
    }
  }
}

.mr-0_5 {
  margin-right: 0.5rem !important;
}
.ml-0_5 {
  margin-left: 0.5rem !important;
}
