.second-content-bg {
  background-color: #f6f8f9;
  margin-top: $base-rem * 2;
  padding: $base-rem * 5 0;
}
.upper-text{
  text-align: justify;
}
.lower-text{
  text-align: justify;
}

.main-second-content-container {
}
.second-content-title {
  padding-top: $base-rem * 3;
  padding-bottom: $base-rem * 2;
  font-weight: 700 !important;
}

// common css
.line-height {
  line-height: $base-rem * 2 !important;
}
.new-text-clr {
  color: #505050 !important;
}
