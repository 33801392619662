.energy-work{
    width: 100%;
    background-color: white;

}
.energy-work-title-main{
    font-size: 30px;
    font-weight: 700;
    color: #282828;
    text-align: center;
    padding-top: 4rem;
}
.energy-work-cards{
    padding: 0rem 8rem;
    
}
.energy-work-card{
    margin-top: 2rem;
position: relative;
    width: 100%;
    border: 1px solid #00000045;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.energy-work-num{
    background-color: #662D91;
    min-height: 2.7rem;
    min-width: 2.7rem;
    border-radius: 50%;
    position: absolute;
    left: -22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.energy-work-num-main{
    color: white;
    font-size: 20px;
    font-weight: bolder;
}
.eng-work-desc{
    padding-left: 2em;
}
.eng-work-desc-main{
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}
@media(max-width:900px){
    .energy-work-cards{
        padding: 0rem 2rem;
        
    }
    .eng-work-desc-main{
        padding-right: 1rem;
    }
}