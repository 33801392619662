.server-div{
    background-color: #F6F8F9;
    width: 100%;
    margin-top: 3rem;
    border-top: 1px solid #00000012;
    padding-top: 2rem;
}
.server-api-title-main{
    font-size: 25px;
    font-weight: 400;
    color: #505050;
}
.server-api-title-main2{
    padding-top: 3rem;
    font-size: 25px;
    font-weight: 400;
    color: #505050;
}
.server-api-img-div{
    margin-top: 1.5rem;
    width: 100%;
    gap: 1.7rem;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: row;
}