.work-card-div{
    padding-top: 2rem;
}
.work-card{
    height: 20rem;
    width: 17rem;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #00000033;
    box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
    cursor: pointer;
    &:hover{
        box-shadow: 0 10px 20px rgb(0 0 0 / 30%);

    }
    margin: auto;
}
.work-card-img{
    height: 16.5rem;
    width: 100%;
    
}
.work-card-img-main{
    height: 100%;
    width: 100%;
}
.work-card-sub-main{
    text-align: center;
    padding-top: 1rem;
    font-weight: 900;
    color: #505050;
    font-size: 15px;
}