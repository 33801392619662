.main-card-container {
  margin-top: $base-rem * 2;
}

.main-card-div {
  height: 21rem;
  padding: $base-rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
}

.card-typo-title {
  position: relative;
  margin-top: 0.5rem !important;
  &::after {
    background-color: black !important;
    position: absolute;
    content: "";
    width: 35px;
    height: 2px;
    background-color: var(--base);
    border-radius: 10px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.card-typo-desc {
  margin-top: $base-rem * 2 !important;
  height: 10rem !important;
}
.card-img-div {
  img {
    width: 105px;
    height: 105px;
    margin-top: -70px;
    border-radius: 50%;
  }
}
@media (max-width: 850px) {
  .card-typo-desc {
    height: auto !important;
  }
  .main-card-div {
    height: auto;
  }
}
