.price-table-div{
    width: 100%;
}
.table-row-1st{
    background-color: #EDEDED;
    color: white;
}
.price-table-div{
    // padding: 2rem 7rem;
}
.price-table-container{
    display: flex;
    justify-content: center;
}

.price-table-title{
    font-size: 16px!important;
    font-weight: bold!important;
    border-radius:15px !important;
}
.price-table{
    border: none!important;
}
.price-table-inner{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tablegrid{
display: flex;
flex-direction: column;
}
.tab-price{
    padding-bottom: 2rem;
}
.tableItems{
    border: 1px solid #0000002b;
    min-height: 2.5rem;
    display: grid;
    grid-template-columns: repeat(4,1fr) ;
}
.price-tbl-ttl{
    width: 100%;
    height: 100%;
    background-color: #EDEDED;
    // border-radius: 10px;
    min-height: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.price-tbl-ttl-main{
    
}
.tbl-index-ttl{
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    color: #505050;
}
.price-table-title-index{
    font-size: 15px;
    padding-left: 1rem;
    font-weight: 700;
    line-height: 30px;
    
}
.tbl-index-val{
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    color: #505050;
}
.check-color{
    color: #62BB25;
}
.wrong-color{
    color: red;
}
// .price-tbl-btn-dem{
//     grid-column: 1/span 4;
//     display: flex;
//     justify-content: center;
//     height: 2.5rem;
// }