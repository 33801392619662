.pay-card-inner{
    padding: 4rem 7rem;
}
.pay-div-img{
    height: 100%;
    width: 100%;
}
.pay-img-main{
    height: 18rem;
    width: 100%;
}
.pay-para-content{
    // height: 20rem;
    // width: 25rem;
    width: 100%;
    height: 100%;
    padding-left: 3rem;
}
.pay-div-img{
    padding: 2.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pay-title-main{
    font-size: 30px;
    font-weight: 900;
    color: #505050!important;
    padding-bottom: 1rem;
}
.pay-div-sub{
    padding-bottom: 1rem;
}
.pay-div-link{
}
.pay-sub-main{
    text-align: justify;
    color: #505050!important;
    font-size: 15px;
    line-height: 1.8;
}
.pay-link-main{
    font-weight: bold;
    font-size: 18px;
    color: #682F92;
    text-decoration: underline;
}

@media(max-width:900px){
    .pay-card-inner{
        padding: 2rem 4rem;
    }
    .pay-para-content{
        padding-left: 0rem;
    }
    .pay-img-main{
        height: 18rem;
        width: 29rem;
    }

}
@media(max-width:500px){
    .pay-card-inner{
        padding: 2rem 2rem;
    }
    .pay-img-main{
        height: 13rem;
        width: 100%;
    }

}