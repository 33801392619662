.soc-hlt-title-main{
    font-size: 25px;
    font-weight: 400;
    color: #505050;
    padding-top: 1rem;
}
.soc-hlt-card-div{
    padding-top: 3rem;
}
.soc-hlt-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    &:hover{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}
.soc-hlt-card-img{
    padding-top: 1rem;
}
.soc-hlt-card-title-main{
    font-size: 15px;
    font-weight: bolder;
    text-align: center;
    padding-top: 1rem;
}
.soc-hlt-card-sub-main{
    text-align: center;
    color: #898989;
    line-height: 20px;
    font-size: 14px;
    padding-top: 1rem;
    padding-bottom: 2rem;
}
.soc-hlt-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}
.soc-hlt-btn-main{
    height: 2.5rem;
    width: 9rem;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    background-color: black;
    color:white;
    &:hover{
        background-color: #00ACED;
    }

}