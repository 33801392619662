.req-ban-inner{
    height: 20rem;
    width: 100%;
    background-image: linear-gradient(to right, rgb(0,56,118), rgb(20,215,206));
}
// .req-ban-cont{
//     padding-left: 7.5rem;
// }
.req-ban-title-main{
    color: #fff ;
    font-weight: 800;
    font-size: 40px;
    padding-top: 5rem;
}
.req-ban-sub{
    padding-top: 2rem;
    max-width: 34rem;
}
.req-ban-sub-main{
    color: #fff !important;
    font-size: 20px;
    line-height: 26px;
    font-weight: lighter;
}

@media(max-width:650px){
    // .req-ban-cont{
    //     padding-left: 2rem;
    //     padding-right: 2rem;
    // }
    .req-ban-title-main{
        font-size: 35px;
        padding-top: 4rem;
    }
    .req-ban-sub-main{
        font-size: 16px;
    }

}