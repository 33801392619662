// $base-rem:0.75em
$base-rem: 0.75rem ;

.nav-header-bg {
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}
.active {
  color: #662992;
}

.left-nav-header-div {
  width: 50%;
  img {
    width: 16rem;
  }
}
.right-nav-header-div {
  width: 50%;
}
.list-nav-container-bg{
  display: none !important;
}

.nav-header-li {
  &:hover a {
    color: #662992;
  }
  &:hover .expand-icon {
    color: #662992 !important;
  }
}

.nav-header-solution {
  padding-top: $base-rem * 2;
  padding-bottom: $base-rem * 2;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover .solution-component-div {
    z-index: 100;
    display: flex !important;
    transition: all 0.2s ease-in-out;
  }
}
.hover-nav-header-integration {
  padding-top: $base-rem * 2;
  padding-bottom: $base-rem * 2;
  transition: all 0.2s ease-in-out;
  // background-color: red;
  max-width: 90px;

  position: relative;

  &:hover .payment-component-div {
    z-index: 1000;
    display: block !important;
  }
}

.nav-head-btn-main{
  height: 2.5rem;
  width: 8.5rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(to left, #4527a0 22%, #5e35b1 100%);
  color: white;
  box-shadow: 0px 0px 6px #51388ea8;

}
// drawer style
.menu-icon {
  color: #777 !important;
}
.drawer-ul {
  padding-right: $base-rem;
  padding-left: $base-rem;
}

// Accordion style

.main-acc-logo {
  width: 10rem;
  object-fit: cover;
}

.payment-child-items {
  padding: 0 !important;
}
.main-acc-component {
  padding: $base-rem 0;
}

@media screen and (max-width: 850px) {
  .main-acc-div-wrapper {
    display: block !important;
  }
  .main-nav-header-container {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    display: none !important;
  }
  .list-nav-container-bg{
    display: block !important;
  }
  .nav-header-ul {
    display: none !important;
  }
  .menu-icon-btn {
    display: block !important;
  }
  .left-nav-header-div {
    img {
      width: 12rem;
    }
  }
  .right-nav-header-div {
    width: 100%;
  }
}

// <------------------------------------------------>
.list-nav-main-div{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.6rem;
  img{
    width: 10rem;
    object-fit: cover;
  }
}



