@import "./realestate";
@import "./demo-page";
@import "./equity";
@import "./reward";
@import "./donation";
@import "./lending";
@import "./lending-feature";
@import "./lending-famous";
@import "./employee";
@import "./employ-welfare";
@import "./energy";
@import "./energy-banner";
@import "./energy-intro";
@import "./energy-highlight";
@import "./energy-contact";
@import "./energy-work";
@import "./contact-banner";
@import "./faqs-banner";
@import "./faqs-qa";
@import "./work-banner";
@import "./price-banner";
@import "./price-card";
@import "./price-table";
@import "./work-card";
@import "./price-page";
@import "./contact-form";
@import "./contact-info";
@import "./payment-gateway";
@import "./payment-card";
@import "./req-banner";
@import "./req-form";
@import "./product-page";
@import "./product-banner";
@import "./demo-tabs";
@import "./demo-feature";
@import "./demo-overview";
@import "./social-media";
@import "./social-high";
@import "./server-api";

.term-heading{
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}
.term-header-small{
    padding-top: 1.5rem;
    padding-bottom: 1.3rem;
}
.small-pad{
padding-top: 1rem;
padding-bottom: 1rem;
padding-left: 1rem;
}