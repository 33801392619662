@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500&family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500&family=Rubik:wght@600&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif !important;
}
a {
  text-decoration: none;
  color: #777 ;
  font-size: 0.9rem;
}

.list-style-none {
  list-style: none;
}

//height
.h-vh100{
  height: 100vh;
}


$base-rem: 0.75rem ;




// width
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.min-w-100 {
  min-width: 100% !important;
}

// display
.d-flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.justify-cnt {
  justify-content: center !important;
}
.justify-btw {
  justify-content: space-between !important;
}
.justify-aro {
  justify-content: space-around !important;
}
.justify-end {
  justify-content: flex-end;
}

.align-cnt {
  align-items: center !important;
}
.d-none {
  display: none !important;
}
.text-align-cnt {
  text-align: center !important;
}
// gap
.g-1 {
  gap: 1rem !important;
}
.g-2 {
  gap: 2rem !important;
}

// color
.main-bg-clr {
  background-color: #662992 !important;
}
.main-text-clr {
  color: #662992 !important;
}
.clr-white {
  color: white !important;
}
.clr-black {
  color: black !important;
}
.clr-light {
  color: #777 !important;
}
// padding & margin
.mr-1 {
  margin-right: 1rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}
// font
.font-size-0_9 {
  font-size: 0.9rem !important;
}
// shadow
// btn
.btn-contained {
  background-color: #662992 !important;
  text-transform: capitalize !important;
  border: none !important;
  cursor: pointer;
}
.btn-outlined {
  border: 1px solid #662992 !important;
  color: #662992 !important;
  text-transform: capitalize !important;
}

.para-bold-text{
  font-size: 28px;
    line-height: 1.2;
    font-weight: 900;
}
.para-light-text{
  color: #505050;
  font-size: 15px;
  line-height: 1.8;
  text-align: justify;

}
para-mid{
  text-align: center;
  width: 100%;
}
