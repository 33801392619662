.faqs-qa {
    width: 100%;
    // padding: 0rem 7rem;
    padding-top: 3rem;
}

.faq-qa-title-main {
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 1rem;
    color: #000000b3;
}
.faq-qa-title{
    font-size:1rem!important;
}
.faq-link-ans{
    color: #662D91;
    cursor: pointer;
}
.faq-list-ans{
    padding-left: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
}
.faq-ans-ul{
    padding-left: 1rem;
    padding-bottom: 1rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.faqs-qa-inner {}

.accordion-style {
    margin-bottom: 1.4rem;
    border-radius: 10px;

}

@media(max-width:900px){
    .faqs-qa {
        // padding: 0rem 1.5rem;
        padding-top: 3rem;
    }

}