.main-new-solution-container{
    position: absolute !important;
    top: 70px !important;
    left: 0px;
    transform: translateX(-30%);
    //   right: 0 !important;
    //   padding-top: 3rem !important;
    //   transform: translate(-200px, 5px) !important;
    width: fit-content !important;
}
.new-solution-div-wrapper {
    background-color: white;
    box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.1);
    //   width: fit-content;
    padding: $base-rem * 2;
    width: 10rem;
  }
.new-sol-nav-title{
    padding-bottom: 1rem;
    font-size: 13px;
    font-weight: 500;
    &:hover{
        font-weight: 700;
    }
}
.new-sol-grid-item{
    &:hover{
        cursor: pointer;

    }
}