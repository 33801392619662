
.contact-form{
    height: 38rem;
    width: 100%;
    display: flex;
    justify-content: center;
    // padding: 2rem 7rem;
}
.contact-form-inner{
    height: 100%;
    width: 100%;
    background-color: powderblue;
    border-radius: 10px;
    background-image: url("https://images.unsplash.com/photo-1423666639041-f56000c27a9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80");
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;

}
.contact-card{
    // height: 26rem;
    // width: 21rem;
    background-color: white;
    position: absolute;
  top: 50%;
    left: 76%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
}
.contact-card-inner{
    padding: 1.5rem;
}
.send-msg{
    width: 100%;
}
.send-msg-main{
    padding-top: 0.7rem;
    color: #000000b0;
    font-weight: bold;
}
.contact-field{
    min-width: 17rem;
    padding-top: 0.8rem;
}
.contact-name{
    width: 95%;
}
.contact-box{
    width: 95%;

}
.pref-text{
    padding-top: 0.5rem;
    font-size: 15px;
    color: #000000b0;
}
.contact-check{
    padding-top: 0.8rem;
    padding-left: 2rem;
    display: flex;
    gap: 3rem;
}
.contact-check-text{
    color: #000000b0;
    font-size: 15px;
    padding-left: 0.3rem;

}
.contact-btn-div{
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.contact-form-btn{
    height: 3rem;
    width: 13rem;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    font-weight: bold;
    color: white;
    background-color: #662D91;
    cursor: pointer;
    &:hover{
        background-color: #7f0cd8;
    }
}

@media(max-width:950px){
    .contact-form{
        // height: 35rem;
        // width: 100%;
        // display: flex;
        // justify-content: center;
        padding: 2rem 2rem;
    }
    .contact-card{
        // height: 26rem;
        // width: 21rem;
        
        left: 65%;
        
    }
    .contact-form-inner{
        width: 100%;
    }

}
@media(max-width:950px){
    .contact-card{
        // height: 26rem;
        // width: 21rem;
        
        left: 50%;
        
    }

}
@media(max-width:440px){
    .contact-form{
        
        padding: 0rem 0rem;
    }
}

