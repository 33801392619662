.real-estate-div{
    width: 100%;
}
.real-estate-inner{
    height: 100%;
    width: 100%;
    background-image: url('https://d1suf988f4fj5v.cloudfront.net/images/real-estate-banner-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.real-estate-hero{
    height: 70%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 4rem;

}
.real-estate-hero-left{
    height: 100%;
    width: 41%;
}
.real-estate-hero-right{
    height: 100%;
    width: 47%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.real-estate-hero-title{
    padding-top: 2rem;
    font-size: 30px;
    font-weight: lighter;
    color: #282828;
    text-transform: inherit;
}
.real-estate-hero-title2{
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #282828;
    text-transform: inherit;
    padding-bottom: 0.5rem;
}
.crowd-div-realestate{
    font-weight: 700;
}
.real-estate-lapi-img{
    height: 19rem;
    width: 32rem;
}
.real-est-sub{

}
.real-est-sub-main{
    line-height: 27px;

}
.real-est-buttons{
    display: flex;
    gap: 1rem;
    padding-top: 1.5rem;
}
.real-est-btn1-main{
    height: 2.7rem;
    width: 10rem;
    color: white;
    background-color: #683092;
    border: none;
    // border-color: #683092;
    border-radius: 5px;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;

}
.real-est-btn2-main{
    height: 2.7rem;
    width: 10rem;
    color: white;
    background: transparent;
    border: 1px solid #683092;
    color: #683092;
    border-radius: 5px;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    &:hover{
        background-color: #683092;
        color: white;
    }
}
.real-est-hero-last{
    height: 15rem;
   right: 0;
   position: absolute;
   transform: translateX(68%);
}
.integrate-num{
    height: 2.2rem;
    width: 3.3rem;
    background-color: #662E91;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.integrate-num-main{
    color: white;
    font-weight: bold;
}
.integrate-1{
    padding-top: 0.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.integrate-para-main{
    font-weight: 600;
    font-size: 15px;
}
@media (max-width:1200px){
    .real-estate-lapi-img{
        
            height: 14rem;
            width: 23rem;
        }
        .real-est-hero-last{
           transform: translateX(48%);
        }
        
    
    }
@media (max-width:1100px){
    .real-estate-lapi-img{
        
            height: 14rem;
            width: 23rem;
        }
        .real-est-hero-last{
           transform: translateX(45%);
        }
        
    
    }
@media(max-width:900px){
    .real-estate-hero-right{
        display: none;
    
    }
    .real-estate-hero-left{
        height: 100%;
        width: 100%;
    }
}
@media(max-width:800px){
    .real-estate-hero{
        padding-top: 1rem;
        padding-bottom: 2rem;
    
    }
    

}
