.lending-famous-div{
    height: 24rem;
    width: 100%;
    background-color: transparent;
    position: relative;
}
.lend-fam-inner{
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.lend-fam-hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.lend-fam-title-main{
    font-weight: 800;
    font-size: 40px;
    color: #fff;
}
.lend-fam-sub{
    color: #fff;
    font-size: 18px;
    line-height: 31px;
    font-weight: 300;
    max-width: 20rem;
    max-width: 48rem;
    text-align: center;
    padding-top: 2rem;
}
.lend-fam-btn{
    padding-top: 2rem;
    width: 100%;
    text-align: center;
}
.lend-fam-btn-main{
    height: 3rem;
    width: 8rem;
    border: none;
    background-color: #662D91;
    color: white;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;

}
@media(max-width:500px){
    .lend-fam-title-main{
        font-size: 30px;
    }
    .lend-fam-sub{
        color: #fff;
        font-size: 13px;
        line-height: 18px;
        padding: 1rem 2rem;
        
    }

}