.demo-feature-title-main{
    padding-top: 4rem;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--font-base-hover);
}
.demo-feature-sub-main{
    padding-top: 1rem;
    color: #505050;
    font-family: Rubik, sans-serif;
    font-size: 15px;
    line-height: 1.8;
}
.demo-feature-card{
    padding-top: 4rem;
}
.demo-feature-card-title-main{
    font-size: 16px;
    line-height: 1.8;
    font-weight: bolder;
}
.demo-feature-card-sub-main{
    padding-top: 1rem;
    color: #505050;
    font-size: 15px;
    line-height: 1.8;
}
.demo-feature-item{
    border: 1px solid rgba(0, 0, 0, 0.109);
    padding: 1rem;
    position: relative;
    min-height: 14rem;
    overflow: hidden;
    border-radius: 10px;
    &:hover{
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}
.demo-feature-card-icon-div{
    height: 9rem;
    width: 9rem;
    border-radius: 50%;
    background-color: #E3F1F7;
    position: absolute;
    right: -70px;
    bottom: -68px;

}
.demo-feature-card-img{
    top: 32px;
    left: 29px;
    position: absolute;
}