.equity-div{
    width: 100%;
}
.equity-inner{
    height: 100%;
    width: 100%;
    background-image: url('https://d1suf988f4fj5v.cloudfront.net/images/equity-banner-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.equity-cont{
    // padding: 0 $base-rem * 5 0!important;
}