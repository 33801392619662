.lending-outer-div{
    height: 100vh;
    width: 100%;
    background-image: url(https://d1suf988f4fj5v.cloudfront.net/images/pre-order-calltoaction-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -999;
    position: fixed;
    top: 0;
}
.lending-inner{
    height: 32rem;
    width: 100%;
    background-color: powderblue;
    background-image: url("https://d1suf988f4fj5v.cloudfront.net/images/pre-order-header-img.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lending-hero{
    width: 100%;
}
.lending-hero-title{
    display: flex;
    justify-content: center;
    align-items: center;
}
.lending-hero-title-main{
    font-weight: 800;
    font-size: 50px;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 0 1px 5px rgb(0 0 0 / 30%);
    text-align: center;
}
.lending-hero-sub{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.lending-hero-sub-main{
    color: #fff;
    font-size: 20px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 30px;
    text-shadow: 0 1px 1px rgb(0 0 0 / 30%);
    text-align: center;
    width: 61rem;
}
.lending-hero-btn{
    width: 100%;
    text-align: center;
}
.lending-hero-btn-main{
    height: 3rem;
    width: 9rem;
    background-color: #682F92;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 25px;
    font-size: 15px;
    font-weight: bold;

}
@media(max-width:100px){
    .lending-hero-sub-main{
        width: auto;
    }
    .lending-hero{
        margin: 0rem 4rem;
    }

}

@media(max-width:750px){
    .lending-hero-sub-main{
        font-size: 15px;
        width: auto;
    }
    .lending-hero{
        margin: 0rem 3rem;
    }
    .lending-hero-sub-main{
        
        line-height: 25px;
        
    }

}
@media(max-width:600px){
    .lending-hero-sub-main{
        font-size: 12px;
        width: auto;
    }
    .lending-hero-title-main{
        font-size: 30px;
    }

}