.energy-div{
    height: 100vh;
    width: 100%;
    position: fixed;
    background-image: url("https://d1suf988f4fj5v.cloudfront.net/images/rec-calltoaction-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -999;
    top: 0;

}