.price-tbl-outer{
    padding: 2rem 0;
}
.price-tbl-main{
    padding: 2rem 3rem;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 50px rgb(226 222 232 / 75%);
}


.price-card-inner {
    padding: 2rem 0;
}

.price-card-heading-main {
    color: #505050!important;
    font-size: 15px;
    line-height: 1.8;
}

@media(max-width:750px){
    .price-card-inner {
        padding: 2rem 0;
    }
    // .price-tbl-outer{
    //     padding: 0rem rem;
    // }
    .price-tbl-main{
        padding: 1rem 1rem;
        
    }

}
