.mini-card-container {
  margin-top: 2rem;
}
.main-mini-card-div {
  position: relative;
  height: 25rem;

  padding: $base-rem;
  border-radius: $base-rem;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  &:hover {
    .mini-img-icon {
      transition: all 0.3s ease-in-out !important;
      transform: scale(1) translateY(-10px) !important;
    }
  }
}

.mini-card-img {
  &:hover {
    .mini-img-icon {
      transform: scale(1) translateY(-10px) !important;
    }
  }
  &::before {
    z-index: -100;
    position: absolute;
    content: "";
    width: 91px;
    height: 15px;
    background-color: #c07bf1;
    border-radius: 10px;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.mini-img-icon {
  width: 5rem !important;
  transform: translateY(0) !important;
  transition: all 0.3s ease-in-out !important;
}

.icon-border {
  width: 30%;
  border: 10px solid #662992;
}
.mini-card-title {
  padding-top: $base-rem;
  width: 10%;
  border-bottom: 3px solid #662992;
}
.mini-card-text {
  padding-top: $base-rem * 2;
  // height: 12rem;
  text-align: center;
}
.mini-card-btn-div {
  padding: $base-rem 0;
}
.mini-card-btn {
  &:hover {
    background-color: #662992 !important;
    border-radius: 0.3rem !important;
    color: white !important;
  }
}

// @media screen and (max-width: 760px) {
//   .mini-card-container {
//     grid-template-columns: repeat(1, 1fr) !important;
//     margin-top: 2rem;
//     margin-bottom: 2rem;
//   }
// }
