.contact-banner{

}
.contact-banner-inner{
    height: 22rem;
    width: 100%;
    background-image: url("https://d1suf988f4fj5v.cloudfront.net/images/banner-contact-us.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.contact-banner-blur{
    background-color: #00000078;
    height: 100%;
    width: 100%;
}
.contact-banner-content{
    color: white;
}
.contact-banner-title-main{
    font-size: 40px;
    font-weight: 700;
}
.contact-banner-sub-main{
    font-size: 15px;
    line-height: 1.8;
    max-width: 50rem;
    padding-top: 1rem;
}
.banner-card-cont{
    display: flex!important;
    height: 100%;
}
.contact-banner-hero{
    // padding: 0rem 7.5rem;
    // padding-top: 5rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contact-location{
    width: 100%;
}
.contact-location-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.contact-loc-left{
    width: 40%;
    background-color: powderblue;
}
.contact-loc-right{
    width: 60%;
    background-color: grey;
    height: 100%;
}
.contact-loc-content{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.contact-locate-img{
    height: 4rem;
    width: 4rem;
    background-color: #662d9169;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.contact-locate-img-main{
    height: 2.3rem;
}
.contact-locate-title-main{
    font-weight: 700;
    font-size: 18px;

}
.contact-locate-sub-main{
    color: #505050!important;
    font-family: Rubik, sans-serif;
    font-size: 15px;
    line-height: 1.8;
    max-width: 23rem;
    text-align: center;

}

@media(max-width:1000px){
    .contact-location-inner{
        padding: 2rem 2rem;
        flex-wrap: wrap;
    }
}
@media(max-width:800px){
    // .contact-banner-hero{
    //     padding: 0rem 2rem;
    // }
    .contact-banner-sub-main{
        font-size: 12px;
    }
    .contact-banner-title-main{
        font-size: 32px;
    }
}