.demo-ovr-inner {
    padding-top: 4rem;
}

.demo-ovr-cont-title-main {
    font-size: 25px;
    font-weight: 400;
    color: #505050;
}

.demo-ovr-cont-sub-main {
    padding-top: 1rem;
    color: #505050 !important;
    font-size: 15px;
    line-height: 1.8;
    padding-bottom: 3rem;
    text-align: justify;
}

.demo-cont-span {
    color: #3293BF;
}

.demo-ovr-img {
    height: 100%;
    width: 100%;
}