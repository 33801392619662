.blog-outer{
  background-color: white;
  padding-top: 1rem;
}
.main-blog-container {
  margin-top: $base-rem * 3 !important;
  padding-bottom: 3rem;
}
.main-blog-div-wrapper {
  //   width: 20%;
  height: 23rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  &:hover {
    
    transform: translate3d(0, -4px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
}
blog-link-title{
  color: black !important;
  font-size: 1rem!important;
  
}
.blog-img-div {
  width: 100%;
  //   border-radius: 0.5rem 0.5rem 0 0 !important;
  img {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    width: 100%;
    object-fit: cover;
  }
}
.blog-title-div {
  padding: $base-rem;
  cursor: pointer;
}

@media (max-width:600px) {
  .main-blog-div-wrapper{
    height: auto ;
    width: 60%;
    margin:auto;
  }
  
}
@media (max-width:450px) {
  .main-blog-div-wrapper{
    height: auto ;
    width: 85%;
    margin:auto;
  }
  
}
