.social-med-in{
    margin-top: 5rem;
    background-color: #F6F8F9;
    width: 100%;
    // position: relative;
}
.soc-below-div{
    // height: 15rem;
    // background-color: grey;
    // width: 100%;
}
.soc-upper-div{
    background-color: white;
    border-radius: 10px;
    transform: translateY(-50%);
    border: 1px solid rgba(0, 0, 0, 0.116);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    // position: absolute;
}
.soc-top-sec{
    // height: 2rem;
    // width: 30rem;
    max-width: 25rem;
    background-color: #EFEFEF;
    border-radius: 30px;
    margin: auto;
    transform: translateY(-50%);
}
.soc-top-text{
    padding: 0.5rem;
    color: #555050;
    text-align: center;
}
.soc-upper-img-div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;

}
.soc-upper-img-div-sec3{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;

}

.soc-blw-div-title-main{
    font-size: 25px;
    font-weight: 400;
    color: #505050;
}
.soc-blw-div-sub-main{
    padding-top: 1rem;
    text-align: justify;
    color: #505050;
    font-size: 15px;
    line-height: 1.8;
    margin-bottom: 15px;
}
.soc-upper-div2{
    background-color: white;
    border-radius: 10px;
    transform: translateY(50%);
    border: 1px solid rgba(0, 0, 0, 0.116);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    // position: absolute;
}
.soc-med-sec2{
    margin-top: 5rem;
}
.soc-med-card{
    background-color: #479CC4;
    border-radius: 10px;
    cursor: pointer;
}
.soc-med-card2{
    background-color: #3C3C3C;
    border-radius: 10px;
    cursor: pointer;
}
.social-med-in2{
    margin-top: 9rem;
    background-color: #F6F8F9;
    width: 100%;
    // position: relative;
}
.soc-med-sec3-img-div{
    height: 15rem;
    background-color: powderblue;
    width: 100%;
    border-radius: 10px;
    // background-image: url("https://d1suf988f4fj5v.cloudfront.net/images/revenue-banner.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
}
.soc-med-sec3-img-div-main{
    height: 100%;
    width: 100%;
}
.soc-med-sec3{
    margin-bottom: 5rem;
}

@media(max-width:950px){
    .soc-upper-div{
        transform: translateY(-30%);
        // position: absolute;
    }


}
@media(max-width:650px){
    .soc-upper-div{
        transform: translateY(-13%);
        // position: absolute;
    }


}
@media(max-width:700px){
    .soc-upper-div2{
        transform: translateY(17%);
        // position: absolute;
    }
    .soc-med-sec3-img-div{
        height: 7rem;
        background-color: powderblue;
        width: 100%;
        border-radius: 10px;
        // background-image: url("https://d1suf988f4fj5v.cloudfront.net/images/revenue-banner.png");
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: cover;
    }
}
@media(max-width:430px){
    .soc-upper-div{
        transform: translateY(-8%);
        // position: absolute;
    }


}