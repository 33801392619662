.main-integration-wrapper {
  z-index: 2000;
  position: absolute !important;
  top: 70px !important;
  left: -538px !important;
  width: 72rem !important;
}

.main-integration-wrapper {
  background-color: white;
  box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.1);
}

.payment-gateway-section {
  width: 30%;
}
.payment-icon-span {
  &:hover {
    cursor: pointer;
  }
}
.payment-icon {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  object-fit: cover;
  transform: scale(1.5);
}
