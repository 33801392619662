.contact-info-inner{
    padding: 3rem 0;
}
.cont-info-right-inner{
    height: 100%;
    width: 100%;
    
}
// .cont-info-card{
//     padding: 0rem 4rem;
// }
.cont-info-card-inner{
    height: 100%;
    width: 43rem;
    display: flex;
    justify-content: center;
}
.contact-info-form{
    
    width: 46rem;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.164);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
}
.contact-info-form-inner{
    padding: 2rem;
}
.cont-info-form-title-main{
    font-weight: 700;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.822);
    line-height: 1.2;
    padding-bottom: 2rem;
}
.cont-info-form-field{
    padding: 0.5rem 0;
}
.cont-info-btn{
    height: 3rem;
    width: 5rem;
    background-color: #662D91;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    &:hover{
        background-color: #46007c;
    }
}
.phone-in-main{
    width: 2rem;
}
.phone-select-field{
    display: flex;
    width: 100%!important;
}
.ends{
    width: 100%!important;

}
@media(max-width:1000px){
    .contact-info-inner{
        padding: 2rem 0;
    }
    .contact-info-form{
    
        width: auto;
    }
    .cont-info-card-inner{
        width: auto;
        
    }
}

@media(max-width:1200px){
    .contact-info-form{
    
        width: auto;
    }
}

@media(max-width:850px){
    // .cont-info-card{
    //     padding: 0rem 0rem;
    // }
    .contact-info-form{
    
        width: 100%;
        
    }
}