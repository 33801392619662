.main-footer-bg {
  background-color: black;
  color: white;
  // margin-top: $base-rem * 3;
}
// .footer-container {
//   padding: $base-rem * 3 0 !important;
// }
.footer-container{
  padding-top: 3rem;
}
.left-footer-div {
  width: 30%;
}
.footer-img-div {
  img {
    width: 15rem;
    object-fit: cover;
  }
}

.icon-insta {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d,
    #f56040,
    #f77737,
    #fcaf45,
    #ffdc80
  ) !important;
}
.icon-fb {
  background-color: hsl(221, 44%, 46%) !important;
}
.icon-linkedin {
  background-color: #0077b5 !important;
}
.icon-twitter {
  background-color: #1da1f2 !important;
}
.footer-link-li {
  list-style: none;
}
.footer-about-text{
  text-align: justify;
}
.foot-low-bar{
  width: 100%;
  padding: 2rem 0rem;
}
.foot-low-inner{
  display: flex;
  justify-content: center;
}

.google-icon {
  img {
    width: 2rem;
    // margin-right: 0.1rem;
  }
}
.play-icon-img{
  height: 2rem;
  width: 7rem;
  cursor: pointer;
}
.footer-divider {
  background-color: #777 !important;
  margin-left: $base-rem * 2 !important;
  margin-right: $base-rem * 2 !important;
  margin-top: $base-rem * 2 !important;
  //   margin-bottom: $base-rem * 3 !important;
}
.footer-copyright-text{
  color: #fff;
  &:hover{
    color: rgb(163, 163, 163);
  }
}
.footer-content{
  text-align: center;
}
// .foot-low-inner{
//   padding: 0 1rem;
// }

@media (max-width: 860px) {
  .footer-container {
    flex-direction: column !important;
    padding-left: $base-rem !important;
    padding-right: $base-rem !important;
  }
  .left-footer-div {
    width: 100%;
  }
  .right-footer-div {
    margin-top: $base-rem * 2;
    margin-bottom: $base-rem * 2;
  }
  .footer-quick-links {
    text-align: center;
  }
  .footer-solution {
    text-align: center;
  }
  .footer-lower-copy-div {
    padding-top: 0 !important;
    padding-right: 0 !important;
  }
  .foot-low-inner{
    flex-wrap: wrap;
    gap: 0.7rem;
  }
  .foot-low-right{
    width: 100%;
  }
  .foot-low-left{
    width: 100%;
  }
  .foot-low-text{
    width: 100%;
    text-align: center;
  }
  .foot-low-bar{
    width: 100%;
    padding: 2rem 1rem;
  }
}

// <-------Srinu---------->




