.emp-wlf{
    width: 100%;
    background-color: #f6f8f9;
}
.emp-wlf-inner{
    padding: 3rem 7rem;
}
.emp-wlf-title-main{
    font-size: 25px;
    font-weight: 400;
    color: #505050;
    padding-bottom: 2rem;
}
.emp-wlf-sub{

}
.emp-wlf-para{
    font-size: 15px;
    line-height: 1.8;
    color: #505050;
    padding-bottom: 1rem;
    max-width: 70rem;
    text-align: justify;
}
.emp-wlf-title2{
    padding-top: 2rem;
}
.emp-wlf-title-main2{
    font-size: 25px;
    font-weight: 400;
    color: #505050;
    padding-bottom: 1rem;
}
.bold-text{
    padding: 1rem 0rem;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.2;
    color: #505050!important;
}
.for-em-sub-div{
    display: flex;
}
.for-em-sub-main{
    padding-left: 2rem;

}
.simple-para{
    font-size: 15px;
    line-height: 1.8;
    color: #505050;
    padding-bottom: 1rem;
    text-align: justify;
}
.emp-imp-inner{
    padding: 3rem 7rem;

}
.most-imp{
    color: #505050;
    font-size: 15px;
    line-height: 1.8;
    font-weight: bold;
}

@media(max-width:800px){
    .emp-wlf-inner{
        padding: 2rem 2rem;
    }
    .emp-imp-inner{
        padding: 2rem 2rem;
    
    }
}