.product-page{
    width: 100%;
}
.product-page-inner{
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, rgb(0,56,118), rgb(20,215,206));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.hero2-prod-page-col{
    color: white;
}
.prod-sub-col{
    color: white;
}
.prod-btn{
    height: 2.6rem;
    width: 10rem;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
    &:hover{
        background-color: white;
        color: black;
    }
}