.prod-banner{
    height: 30rem;
    width: 100%;

    background-image: linear-gradient(to right, rgb(0,56,118), rgb(20,215,206));
    padding-top: 4rem;

}
.prod-ban-inner{

}
.prod-left-title-main{
    font-size: 44px;
    max-width: 33rem;
    font-weight: 600;
    line-height: 46px;
    color: #fff;
}
.prod-left-sub{
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 100;
    padding-top: 1rem;
}
.prod-left-btn-div{
    padding-top: 1rem;
    display: flex;
    gap: 1rem;
}
.prod-ban-btn{
    height: 2.7rem;
    width: 10rem;
    background: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: 100;
    &:hover{
        background-color: white;
        color: #01417B;
    }
}
.prod-ban-img{
    height: 100%;
    width: 100%;
}

@media(max-width:950px){
    .prod-right-div{
        display: none;
    }

}
@media(max-width:550px){
    .prod-left-title-main{
        font-size: 30px;
        line-height: 30px;
    }
    .prod-left-sub{
        color: #fff;
        font-size: 20px;
        line-height: 30px;
        font-weight: 100;
        padding-top: 1rem;
    }

}