.faqs-banner-inner{
    height: 22rem;
    width: 100%;
    background-image: url("https://d1suf988f4fj5v.cloudfront.net/images/faq_banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.faq-qa-header{
    padding-top: 4rem;
    // padding-left: 7rem;
    color: #505050;
    font-size: 15px;
}
@media(max-width:900px){
    .faq-qa-header{
        padding-top: 2rem;
        // padding-left: 2rem;
        color: #505050;
        font-size: 15px;
    }

}